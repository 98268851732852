import React, { useEffect, useState } from "react";
// import { CSVLink } from "react-csv";
import { BsFiletypeCsv } from "react-icons/bs";
import {
  GrievanceColumns,
  AdmReAdmColumns,
  ChangeinConditionColumns,
  DMEColumns,
  EDColumns,
  HighRiskColumns,
  HospAdmColumns,
  HospDischColumns,
  IPHospitalCommColumns,
  NotesColumns,
  NurseColumns,
  OrderColumns,
  OutboundColumns,
  TherapyColumns,
  TierChangeColumns,
  TriageColumns,
  TuckInCallColumns,
} from "../../components/CareEvent/ColumnsGroup";
import dayjs from "dayjs";
import { useMyContext } from "./myContext";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const extraColumn = {
  title: "Patient Name with DOB",
  dataIndex: "patientNamewithDOB",
};

const DetailedCSV = ({ detailData, event, fromDate, toDate }) => {
  const { formatPhoneNumber } = useMyContext();
  const { utcToLocal } = useMyContext();


  const sortedFullData = detailData;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let selectedColumns = [];

    if (event === "Tuck In Call") {
      selectedColumns = TuckInCallColumns;
    } else if (event === "Therapy Utilization Review") {
      selectedColumns = TherapyColumns;
    } else if (event === "Change in Condition Note") {
      selectedColumns = ChangeinConditionColumns;
    } else if (event.includes("General Notes")) {
      selectedColumns = NotesColumns;
    } else if (event === "Inpatient Hospital Communication Note") {
      selectedColumns = IPHospitalCommColumns;
    } else if (event === "Admission / Re-Admission") {
      selectedColumns = AdmReAdmColumns;
    } else if (event === "High Risk Assessment") {
      selectedColumns = HighRiskColumns;
    } else if (event === "Grievance Template") {
      selectedColumns = GrievanceColumns;
    } else if (event === "ED Visit Template") {
      selectedColumns = EDColumns;
    } else if (event === "Request for Tier Change") {
      selectedColumns = TierChangeColumns;
    } else if (event === "Order / Request Template") {
      selectedColumns = OrderColumns;
    } else if (event === "DME Request") {
      selectedColumns = DMEColumns;
    } else if (event === "Nurse Request Template") {
      selectedColumns = NurseColumns;
    } else if (event === "Hospital Admission Template") {
      selectedColumns = HospAdmColumns;
    } else if (event === "Hospital Discharge Template") {
      selectedColumns = HospDischColumns;
    } else if (event === "Outbound Call") {
      selectedColumns = OutboundColumns;
    } else if (event === "Triage Call") {
      selectedColumns = TriageColumns;
    }

    selectedColumns = [extraColumn, ...selectedColumns]; // Add extra column at the start

    setColumns(selectedColumns);
  }, [event]);

  const formatSpaces = (callType) => {
    return callType.replace(/_/g, " ");
  };

  const formatMedicareAB = ({ medicareA, medicareB }) => {
    let elements = "";

    if (medicareA === "Yes") {
      elements += "Medicare A";
    }

    if (medicareB === "Yes") {
      if (elements) {
        elements += ", ";
      }
      elements += "Medicare B";
    }

    return elements || "";
  };

  const formatReviewDateAndCreatedBy = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];
    const date = reviewDate
      ? dayjs(reviewDate).format("YYYY-MM-DD hh:mm A")
      : "";
    return date === "1900-01-01 12:00 AM" ? `\n${name}` : `${date}\n${name}`;
  };

  const formatReviewDate = ({ reviewDate, createdBy }) => {
    const date = reviewDate ? dayjs(reviewDate).format("YYYY-MM-DD") : "";
    const [name] = createdBy?.split("@") || [""];
    return date === "1900-01-01" ? `\n${name}` : `${date}\n${name}`;
  };

  const formatContactInfo = (name, phoneNumber) => {
    const number = phoneNumber ? formatPhoneNumber(phoneNumber) : "";
    return `${name || ""}\n${
      number === "" || !number || number === "N/A" ? "" : number
    }`;
  };

  const formatDateOfBirth = (patient) => {
    return patient?.label.split(" - ")[1] || "";
  };

  const formatCCECompletedThisYear = (isCCECompletedThisYear) => {
    return isCCECompletedThisYear === "Yes"
      ? "Yes"
      : isCCECompletedThisYear === "No"
      ? "No"
      : "Not found in EMR";
  };

  const formatPhoneNumberwithExtn = (phoneNumber, extension) => {
    const number = phoneNumber ? formatPhoneNumber(phoneNumber) : "";
    return `${number}\n${extension ? `Ext. ${extension}` : ""}`;
  };

  const formatLabelWithOther = (label, other) => {
    return `${label || ""}\n${label === "Other" ? other || "" : ""}`;
  };

  const createdDT = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];
    const date = reviewDate ? utcToLocal(reviewDate) : "";
    return date === "1900-01-01 12:00 AM" ? `\n${name}` : `${date}\n${name}`;
  };

  const renderData = (column, data) => {
    const iData = data[column.dataIndex];

    switch (column?.dataIndex) {
      case "dateOfOrderSubmoission":
      case "dateRequestSent":
        return iData
          ? formatReviewDate({
              reviewDate: iData,
              createdBy: "",
            })
          : "";
      case "reviewDate":
        return iData
          ? formatReviewDate({
              reviewDate: iData,
              createdBy: data?.createdBy ? data?.createdBy : data?.modifiedBy,
            })
          : "";
      case "callType":
        return iData ? formatSpaces(iData) : "";
      case "medicareA":
        return data ? formatMedicareAB(data) : "";
      case "createdDateTime":
        return data
          ? createdDT({
              reviewDate: iData,
              createdBy: data?.createdBy ? data?.createdBy : data?.modifiedBy,
            })
          : "";
      case "followupDateTime":
      case "noteDateTime":
      case "iPCommunicationNoteDateTime":
      case "assessmentDateTime":
      case "providerResponseDateTime":
        return data
          ? formatReviewDateAndCreatedBy({
              reviewDate: iData,
              createdBy: data?.createdBy ? data?.createdBy : data?.modifiedBy,
            })
          : "";
      case "callBackNumber":
        return data
          ? formatPhoneNumberwithExtn(
              data.callBackNumber,
              data.callBackNumberExt
            )
          : "";
      case "phoneNumbers":
        return data?.phoneNumbers ? formatPhoneNumber(data?.phoneNumbers) : "";
      case "attendingPhysician":
        return data
          ? formatContactInfo(
              data?.attendingPhysician,
              data?.attendingPhysicianPhoneNumber
            )
          : "";
      case "hospitalCareManager":
        return data
          ? formatContactInfo(
              data?.hospitalCareManager,
              data?.hospitalCareManagerPhoneNumber
            )
          : "";
      case "erName":
        return data ? formatContactInfo(data?.erName, data?.erTelephone) : "";
      case "hospitalAttendingMDName":
        return data
          ? formatContactInfo(
              data?.hospitalAttendingMDName,
              data?.hospitalAttendingMDContact
            )
          : "";
      case "hospitalCaseManagerName":
        return data
          ? formatContactInfo(
              data?.hospitalCaseManagerName,
              data?.hospitalCaseManagerContact
            )
          : "";
      case "callerName":
        return data
          ? formatContactInfo(data?.callerName, data?.callerContact)
          : "";
      case "hospitalName":
        return data
          ? formatContactInfo(data?.hospitalName, data?.hospitalTelephone)
          : "";
      case "dateOfBirth":
        return data ? formatDateOfBirth(data?.patient) : "";
      case "isCCECompletedThisYear":
        return data
          ? formatCCECompletedThisYear(data?.isCCECompletedThisYear)
          : "";
      case "nporpaLabel":
        return data
          ? formatLabelWithOther(data?.nporpaLabel, data?.nporpaOther)
          : "";
      case "providerNameLabel":
        return data
          ? formatLabelWithOther(
              data?.providerNameLabel,
              data?.providerNameOther
            )
          : "";
      case "createdBy":
        if (
          (event === "Grievance Template" ||
            event === "ED Visit Template" ||
            event === "Request for Tier Change" ||
            event === "Order / Request Template" ||
            event === "DME Request" ||
            event === "Nurse Request Template" ||
            event === "Hospital Admission Template" ||
            event === "Hospital Discharge Template" ||
            event === "Outbound Call") &&
          data?.createdBy
        ) {
          const [name] = data.createdBy.split("@");
          return name;
        } else {
          return data?.createdBy || "";
        }
      case "isThereNewMedication":
        return iData === "Yes" ? `Yes\n${data?.newMedicationNotes}` : "No";
      case "isMedicationArrived":
        return iData === "No" ? `No\n${data?.medicationDeliveryNotes}` : "Yes";
      case "isNCPFollowUpCallSetUpDone":
        return iData === "No" ? `No\n${data?.ncpFollowUpCallNotes}` : "Yes";
      case "location":
        return iData === "Other"
          ? `${iData}\n${data?.locationOtherNotes}`
          : `${iData}`;
      default:
        return iData || "";
    }
  };

  // const csvData = sortedFullData.map((item) => {
  //   let csvRow = {};
  //   columns.forEach((col) => {
  //     csvRow[col.title] = renderData(col, item);
  //   });
  //   return csvRow;
  // });

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Detailed Report");

    // Add columns
    worksheet.columns = columns.map((col) => ({
      header: col.title,
      key: col.dataIndex,
    }));

    // Add rows with formatted data
    sortedFullData.forEach((item) => {
      let row = {};
      columns.forEach((col) => {
        row[col.dataIndex] = renderData(col, item);
      });
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        if (cellValue.length > maxLength) {
          maxLength = cellValue.length;
        }
      });
      column.width = maxLength + 1; // Add some padding
    });

    // Enable wrap text for all cells to handle new lines
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true, vertical: "top" };
      });
    });

    // Create buffer and download
    const buffer = await workbook.xlsx.writeBuffer();
    const fileName = `${event.replace(
      /\s+/g,
      "_"
    )}_report_From_${fromDate}_To_${toDate}.xlsx`;
    saveAs(new Blob([buffer]), fileName);
  };

  return (
    <>
      {/* <CSVLink data={csvData} filename={"Genreport.csv"}>
        <BsFiletypeCsv size={"2em"} color="red" className="tw-cursor-pointer" />
      </CSVLink> */}
      <BsFiletypeCsv
        size={"2em"}
        className="tw-cursor-pointer tw-text-blue-600"
        onClick={handleDownload}
      />
    </>
  );
};

export default DetailedCSV;
